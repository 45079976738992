require('./bootstrap');


var checkBox = document.getElementById("formCheck");
checkBox.addEventListener('click', function (e) {
    if (checkBox.checked == true){
        document.getElementById('submitFormBtn').disabled = false;
    } else {
        document.getElementById('submitFormBtn').disabled = true;
    }    
});

